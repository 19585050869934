<template>
  <section v-show="user.information" class="addin">
    <div v-html="$t('OLD_VERSION')"></div>
    <br /><br />
    <button
      :src="sefos_url"
      target="_blank"
      class="btn btn-block btn-fill btn-primary"
    >
      {{ $t("OPEN_SEFOS_FOR_WEB") }}
    </button>
  </section>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {},
  computed: {
    sefos_url() {
      return this.user.hostname.replace("/server/rest", "");
    },
  },
  mounted() {
    console.log("ComposeOld mounted");
  },
};
</script>
<style></style>
